<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="供货商编码" >
                <el-input v-model="search.gongHuoShangBM" size="small" />
            </x-search-item>
            <x-search-item label="供货商名称" >
                <el-input v-model="search.gongHuoShangMC" size="small" />
            </x-search-item>
            <x-search-item label="联系人" >
                <el-input v-model="search.lianXiRen" size="small" />
            </x-search-item>
            <x-search-item label="启用状态" >
                <x-selector-one v-model="search.use" size="small" dictType="T_USE"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column label="序号" type="index" fixed/>
            <el-table-column  prop="gongHuoShangBM" label="供货商编码" />
            <el-table-column  prop="gongHuoShangMC" label="供货商名称" />
            <el-table-column  prop="lianXiRen" label="联系人" />
            <el-table-column  prop="lianXiRenDH" label="联系人电话" />
            <el-table-column  prop="xiangXiDZ" label="详细地址" />
            <el-table-column  prop="jingYingFS" label="经营方式">
                <x-dict-show slot-scope="{row}" :code="row.jingYingFS" dictType="T_JING_YING_FS" />
            </el-table-column>
            <el-table-column  prop="jieSuanFS" label="结算方式" />
            <el-table-column  prop="jieSuanTianShu" label="结算天数" />
            <el-table-column  prop="yinXingKaHao" label="银行卡号" />
            <el-table-column  label="启用状态">
                <x-dict-show slot-scope="{row}" :code="row.use" dictType="T_USE" />
            </el-table-column>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>
                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/cggl/GongHuoShang";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/cggl/ghs/GongHuoShangEdit";
    import Detail from "@/view/cggl/ghs/GongHuoShangDetail";
    import {DICT_USE} from "@/util/constant";

    export default {
        name: "GongHuoShangList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                isUser:DICT_USE,
                search: {
                    gongHuoShangBM: '',
                    gongHuoShangMC: '',
                    lianXiRen: '',
                    use: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>
