<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="供货商名称" prop="gongHuoShangMC" >
                <el-input v-if="form.id!=null" v-model="form.gongHuoShangMC"  size="small" maxlength="36" disabled show-word-limit/>
                <el-input v-else  v-model="form.gongHuoShangMC"  size="small" maxlength="36"  show-word-limit/>
            </el-form-item>
            <el-form-item label="联系人" prop="lianXiRen" >
                <el-input v-model="form.lianXiRen" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="联系人电话" prop="lianXiRenDH" >
                <el-input v-model="form.lianXiRenDH" size="small" maxlength="11" show-word-limit/>
            </el-form-item>
            <el-form-item label="详细地址" prop="xiangXiDZ" >
                <el-input v-model="form.xiangXiDZ" size="small" maxlength="64" show-word-limit/>
            </el-form-item>
            <el-form-item label="经营方式" prop="jingYingFS">
                <x-selector-one v-model="form.jingYingFS" size="small" dictType="T_JING_YING_FS"/>
            </el-form-item>
            <el-form-item label="结算方式" prop="jieSuanFS" >
                <el-input v-model="form.jieSuanFS" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="结算天数" prop="jieSuanTianShu" >
                <el-input v-model="form.jieSuanTianShu" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="扣点比例" prop="kouDianBiLi" >
                <el-input v-model="form.kouDianBiLi" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="开户银行" prop="kaiHuYinXing" >
                <el-input v-model="form.kaiHuYinXing" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="银行卡号" prop="yinXingKaHao" >
                <el-input v-model="form.yinXingKaHao" size="small" maxlength="19" show-word-limit/>
            </el-form-item>
            <el-form-item label="备注" prop="beiZhu" >
                <el-input v-model="form.beiZhu" size="small" maxlength="64" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/cggl/GongHuoShang";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import CityPicker from "../../../components/CityPicker/index";
    import {DICT_TYPE_JING_YING_FS} from "@/util/constant";

    export default {
        components: {CityPicker},
        mixins: [XEditBase],
        data() {
          return {
            id: '', // 当前页面的id
            isAdd: true,
          }
        },
        beforeCreate() {
            let {required,bankCard,positiveEightTwo,digits,phone} = ruleBuilder;
            this.rules = {
                gongHuoShangBM: [], // 供货商编码
                gongHuoShangMC: [required('string', ['blur', 'change'])], // 供货商名称
                lianXiRen: [required('string', ['blur', 'change'])], // 联系人
                lianXiRenDH: [required('string', ['blur', 'change']),phone()], // 联系人电话
                suoZaiQY: [], // 所在区域
                xiangXiDZ: [], // 详细地址
                jingYingFS: [], // 经营方式
                jieSuanFS: [], // 结算方式
                jieSuanTianShu: [digits()], // 结算天数
                kouDianBiLi: [positiveEightTwo()], // 扣点比例
                kaiHuYinXing: [], // 开户银行
                yinXingKaHao: [required('string', ['blur', 'change']),bankCard()], // 银行卡号
                beiZhu: [], // 备注
                use: [], // 启用/禁用
            }
            this.titlePrefix = '供货商';
            this.defaultForm = {
                id: null,
                gongHuoShangBM: "", // 供货商编码
                gongHuoShangMC: "", // 供货商名称
                lianXiRen: "", // 联系人
                lianXiRenDH: "", // 联系人电话
                suoZaiQY: "", // 所在区域
                xiangXiDZ: "", // 详细地址
                jingYingFS: "", // 经营方式
                jieSuanFS: "", // 结算方式
                jieSuanTianShu: "", // 结算天数
                kouDianBiLi: "", // 扣点比例
                kaiHuYinXing: "", // 开户银行
                yinXingKaHao: "", // 银行卡号
                beiZhu: "", // 备注
                use: "true", // 启用/禁用
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        mounted() {
            if (this.id){
                // 在页面加载后，如果需要进行查询操作，则调用getUpdateService方法获取数据并进行回显
                this.getUpdateService(this.id).then((res) => {
                    this.form = Object.assign({}, this.form, res.data); // 将获取到的数据与form对象合并赋值
                    this.isAdd = false; // 将 isAdd 的值设为 false，表示当前是修改页面
                }).catch((error) => {
                    console.error('查询供货商信息失败', error);
                });
            }else {
                this.isAdd = true;
            }
        }
    }
</script>

<style scoped>

</style>
